/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// Core Components

function DemoFooter() {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="row-grid align-items-center mb-5">
            <Col lg="6">
              <h3 className="text-primary font-weight-light mb-2">
                Thank you for your support!
              </h3>
              <h4 className="mb-0 font-weight-light">
                Follow the campaign on any of these platforms.
              </h4>
            </Col>
            <Col className="text-lg-center btn-wrapper" lg="6">
              <Button
                className="btn-icon-only rounded-circle"
                color="facebook"
                id="tooltip844497435"
                href="https://www.facebook.com/tallentforjudge"
              >
                <span className="btn-inner--icon">
                  <i className="fab fa-facebook"></i>
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip844497435">
                Like us
              </UncontrolledTooltip>
              <Button
                className="btn-icon-only rounded-circle"
                color="instagram"
                id="tooltip564081339"
                href="https://www.instagram.com/VirginiaTallentforJudge"
              >
                <span className="btn-inner--icon">
                  <i className="fab fa-instagram"></i>
                </span>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip564081339">
                Follow us
              </UncontrolledTooltip>
            </Col>
          </Row>
          <hr></hr>
          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright">
                {new Date().getFullYear()} PAID FOR BY THE COMMITTEE TO ELECT
                VIRGINIA TALLENT
              </div>
            </Col>
            <Col md="6">
              <div className="copyright">
                Email: info (at) tallentforjudge (dot) com
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default DemoFooter;
