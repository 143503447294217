import React from "react";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

function Form1() {
  const [firstNameFocus, setFirstNameFocus] = React.useState("");
  const [lastNameFocus, setLastNameFocus] = React.useState("");
  const [phoneFocus, setPhoneFocus] = React.useState("");
  const [emailFocus, setEmailFocus] = React.useState("");

  return (
    <>
      <div className="section form">
        <Container fluid>
          <Row className="mt-5 mb-4 pt-5">
            <Col className="ml-auto mr-auto text-center mt-5" md="8">
              <Badge color="info">Get Involved</Badge>
              <h1 className="title">
                Volunteer for Virginia Tallent for Judge
              </h1>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto" md="6">
              <Card
                className="bg-secondary p-3"
                id="contact-form"
                method="post"
                role="form"
                tag="form"
                data-netlify="true"
              >
                <input type="hidden" name="form-name" value="contact-form" />
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup className={firstNameFocus}>
                        <label>First name</label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            aria-label="First Name"
                            placeholder="First Name"
                            type="text"
                            name="firstName"
                            onFocus={() => setFirstNameFocus("focused")}
                            onBlur={() => setFirstNameFocus("")}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className={lastNameFocus}>
                        <label>Last name</label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            aria-label="Last Name"
                            placeholder="Last Name"
                            type="text"
                            name="lastName"
                            onFocus={() => setLastNameFocus("focused")}
                            onBlur={() => setLastNameFocus("")}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className={emailFocus}>
                        <label>Email address</label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            name="email"
                            onFocus={() => setEmailFocus("focused")}
                            onBlur={() => setEmailFocus("")}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className={phoneFocus}>
                        <label>Phone Number</label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-mobile-button"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Phone"
                            type="text"
                            name="phone"
                            onFocus={() => setPhoneFocus("focused")}
                            onBlur={() => setPhoneFocus("")}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label>Address</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-square-pin"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-control-alternative"
                        id="address"
                        name="address"
                        rows="6"
                        type="text"
                      ></Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label>How would you like to get involved?</label>
                    <Input
                      className="form-control-alternative"
                      id="message"
                      name="message"
                      rows="6"
                      type="text"
                    ></Input>
                  </FormGroup>
                  <Row className="justify-content-end">
                    <Col className="text-right" md="6">
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Form1;
