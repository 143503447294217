import React from "react";
// reactstrap components

// Core Components
import DemoNavbar from "components/navbars/DemoNavbar.js";
import Form1 from "components/forms/Form1.js";
import DemoFooter from "components/footers/DemoFooter.js";

function GetInvolved() {
  React.useEffect(() => {
    document.body.classList.add("get-involved");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("get-involved");
    };
  });
  return (
    <>
      <DemoNavbar type="primary" />
      <div className="wrapper">
        <Form1 />
        <DemoFooter />
      </div>
    </>
  );
}

export default GetInvolved;
