import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Feature8() {
  return (
    <>
      <div className="section features-8">
        <Container>
          <Row className="align-items-center text-left">
            <Col lg="8" xs="12">
              <p className="lead pb-4">
                In her current role as Assistant City Manager for the City of
                Cincinnati, Virginia works closely with City residents, and
                community stakeholders on complex issues and problems –
                including racial justice, gun violence, and homelessness. She
                manages high-level operations and strategy for the City’s Health
                and Human Services work – including management of the Emergency
                Communications Center, Department of Transportation &amp;
                Engineering, Office of Human Services, and the Office of
                Environment &amp; Sustainability. Her responsibilities include
                assisting with management of the City’s Police &amp; Fire
                Departments.
              </p>
              <p className="lead pb-4">
                Virginia has years of experience supporting our community’s most
                vulnerable and underrepresented constituents. First as a legal
                aid lawyer, defending low-income tenants from eviction, and
                fighting for the rights of disabled children in education law
                matters. And later, as an attorney for the City of Cincinnati
                working to eliminate nuisance and blight in our community. She
                has also previously served as the City of Cincinnati’s Director
                of Human Services. Virginia has a Juris Doctorate from the
                University of Cincinnati College of Law (Cum Laude), and a
                Bachelor’s degree in Political Science from the University of
                Missouri-Columbia (Summa Cum Laude). Virginia lives in
                Cincinnati with her husband Bryan, and their two sons.
              </p>
            </Col>
            <Col className="pl-0" lg="4" xs="12">
              <img
                alt="..."
                className="ml-lg-5"
                src={require("assets/img/ill/law-photo-2.jpeg")}
                width="100%"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature8;
