import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Header6() {
  return (
    <>
      <header className="header-6 ">
        <div className="page-header">
          <Container>
            <Row className="align-items-center text-left">
              <Col className="pl-0" lg="7">
                <img
                  alt="..."
                  className="ml-lg-5"
                  src={require("assets/img/ill/law-photo-3.jpeg")}
                  width="90%"
                ></img>
              </Col>
              <Col lg="5">
                <p className="lead pb-4">
                  Virginia is a career long public servant with a track record
                  of centering the needs of community first. The common thread
                  through her work is a passion for achieving solutions to
                  complex social problems; and systems-change work, driven by
                  collaboration with others. She is known as a fair, reasonable,
                  and results oriented leader. Her ability to broadly understand
                  how social and judicial systems work together has the
                  potential to make her an innovator on the bench in Hamilton
                  County.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
}

export default Header6;
