import React from "react";

// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import DemoNavbar from "components/navbars/DemoNavbar.js";
import DemoFooter from "components/footers/DemoFooter.js";
import Header6 from "components/headers/Header6.js";
import Feature8 from "components/features/Feature8.js";

function MeetCandidate() {
  React.useEffect(() => {
    document.body.classList.add("meet-virginia-tallent");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("meet-virginia-tallent");
    };
  });
  return (
    <>
      <DemoNavbar type="primary" />
      <div className="wrapper">
        <Header6 />
        <Feature8 />
      </div>
      <DemoFooter />
    </>
  );
}

export default MeetCandidate;
