import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Feature1() {
  return (
    <>
      <div className="section features-1">
        <Container>
          <Row>
            <Col md="3">
              <img
                alt="..."
                className="ml-lg-3"
                src={require("assets/img/ill/77DC928E-D7C6-485D-9A74-D7691DC32341.jpg")}
                width="100%"
              ></img>
            </Col>
            <Col md="3">
              <h6 className="info-title text-uppercase text-info">
                Fairness and Efficiency
              </h6>
              <p className="description opacity-8">
                Virginia is dedicated to fostering fairness and efficiency at
                our courthouse. Virginia’s goal is to build a judicial system
                that provides equal justice for our community in Hamilton
                County. Efficient case management is essential for the smooth
                operation of our court and, more importantly, for the timely
                resolution of legal matters that impact the lives of individuals
                and families in our community. She will fight for a more
                efficient and accessible courthouse for all.
              </p>
            </Col>

            <Col md="3">
              <h6 className="info-title text-uppercase text-info">
                Justice Innovation
              </h6>
              <p className="description opacity-8">
                We must modernize our courthouse and bring it into the 21st
                Century. Virginia is committed to partnering with all
                stakeholders to bring data and technical innovation to Hamilton
                County’s court system. She will fight to make the courthouse
                more responsive and transparent. Virginia believes the public
                deserves access to information about how our justice system is
                working, including routine and regular access to sentencing
                data.
              </p>
            </Col>
            <Col md="3">
              <h6 className="info-title text-uppercase text-info">
                Trust and Integrity
              </h6>
              <p className="description opacity-8">
                Trust and integrity are the foundation of our judicial system.
                The public must have confidence in the fairness and impartiality
                of our judges. Virginia is committed to upholding the highest
                ethical standards and will work to ensure that our courthouse is
                a place where everyone is treated with dignity and respect.
                Virginia will work to build trust in our judicial system by
                ensuring that all people are treated fairly and equally under
                the law.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature1;
